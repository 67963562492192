import { render, staticRenderFns } from "./AgencyManagement.vue?vue&type=template&id=7c850898&scoped=true&"
import script from "./AgencyManagement.js?vue&type=script&lang=js&"
export * from "./AgencyManagement.js?vue&type=script&lang=js&"
import style0 from "./AgencyManagement.scoped.scss?vue&type=style&index=0&id=7c850898&prod&lang=scss&scoped=true&"
import style1 from "./AgencyManagement.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c850898",
  null
  
)

export default component.exports