import axios from 'axios'

const state = {
  actions: [],
  actionsLoading: false,
  actionUpdating: false
}

const actions = {
  getActions({
    commit,
  }, lodgingId) {
    commit('setActionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/lodgings/' + lodgingId + '/actions', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setActions', response.data)
        commit('setActionsLoading', false)
        resolve(response)
      }).catch(error => {
        commit('setActionsLoading', false)
        reject(error.response)
      })
    })
  },

  getActionsByRoom({
    commit,
  }, room) {
    commit('setActionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/lodgings/' + room.lodging_id + '/rooms/' + room.id + '/actions', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setActions', response.data)
        commit('setActionsLoading', false)
        resolve(response)
      }).catch(error => {
        commit('setActionsLoading', false)
        reject(error.response)
      })
    })
  },

  createAction({
    commit,
  }, action) {
    return new Promise((resolve, reject) => {
      commit('setActionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + action.agency_id + '/lodgings/' + action.lodging_id + '/rooms/' + action.room_id + '/actions', action, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setActionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setActionUpdating', false)
        reject(error)
      })
    })
  },

  deleteAction({
    commit,
  }, action) {
    return new Promise((resolve, reject) => {
      commit('setRoomUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/agencies/' + action.agency_id + '/lodgings/' + action.lodging_id + '/rooms/' + action.room_id + '/actions/' + action.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setRoomUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setRoomUpdating', false)
        reject(error)
      })
    })
  },
}


const getters = {
  actions: state => state.actions,
  actionsLoading: state => state.actionsLoading,
  actionUpdating: state => state.actionUpdating
}

const mutations = {
  setActions(state, actions) {
    state.actions = actions
  },
  setActionsLoading(state, actionsLoading) {
    state.actionsLoading = actionsLoading
  },
  setActionUpdating(state, actionUpdating) {
    state.actionUpdating = actionUpdating
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}