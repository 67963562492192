import MissionDialogI18n from './MissionDialog.i18n.js'
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import {
  mapActions,
  mapGetters
} from 'vuex';
import Vue from 'vue'
Vue.use(DatetimePicker)

export default {

  components: {},

  props: {
    value: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "create"
    },
    dialogOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['rooms', 'lodgings', 'agency']),
    formTitle() {
      return this.value.id == -1 ? 'Créer une mission' : 'Modifier une mission'
    },
    isUpdating() {
      return this.value.id > -1
    },
    buttonTitle() {
      return this.value.id == -1 ? 'Créer' : 'Modifier'
    },
    totalTime() {
      let hours = 0
      let minutes = 0
      if (this.value.duration) {
        hours = Math.floor(this.value.duration / 60)
        minutes = this.value.duration % 60
        return hours > 0 ? hours + ' heures et ' + minutes + ' minutes' : minutes + ' minutes'
      }
      else {
        return 'Non défini'
      }
    },
    totalCost() {
      if (!this.totalTime || this.totalTime == 'Non défini') {
        return 'non défini'
      } else {
        return 'de ' + (parseInt(this.value.duration) * (this.value.hourly_rate / 60)) + '€'
      }
    }
  },

  data() {
    return {
      e1: 1,
      dialog: false,
      menuDateStart: false,
      menuTimeStart: false,
      menuDateEnd: false,
      menuTimeEnd: false,
      isValid: true,
      datetime: null,
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      lodgingRules: [
        v => !!v || 'Un logement doit être sélectionné',
      ],
      dateRules: [
        () => !this.value.startRelease || !this.value.endRelease || (this.value.startRelease < this.value.endRelease) || 'La date de début de publication ne peut pas être supérieure à la date de fin',
      ],
      selectedRooms: []
    };
  },

  watch: {
    dialogOpen(val) {
      if (val) {
        this.$refs.form.resetValidation()
        this.getActionsForLodging()
      }
      val || this.close()
    },
  },

  methods: {
    ...mapActions(['getLodgings', 'getLodgingRoomsAndActions']),
    /**
     * Fonction pour formatter la date.
     * @param {*} pDate 
     * @returns 
     */
    getDateFormattedMomentjs(pDate) {
      moment.locale('fr');
      return pDate ? moment(pDate).format('dddd Do MMMM YYYY') : '';
    },

    closeDatePicker() {
      this.menu = false
    },

    close() {
      this.e1 = 1
      this.$emit('close')
    },

    /**
     * Récupère les actions possibles pour le logement.
     */
    getActionsForLodging() {
      if (this.value.lodging_id) {
        this.getLodgingRoomsAndActions(this.value.lodging_id).then(() => {
          if (this.isUpdating) {
            this.rooms.forEach(aRoom => {
              const currentRoom = this.value.rooms.find(room => room.id === aRoom.id)
              aRoom.actions.forEach(action => {
                let found = false
                currentRoom.actions.forEach(currentAction => {
                  if (currentAction.id === action.id) {
                    currentAction.isSelected = true
                    found = true
                  }
                })
                if (!found) {
                  action.isSelected = false
                  currentRoom.actions.push(action)
                }
              })
            })
          } else {
            this.value.rooms = []
            this.rooms.forEach(aRoom => {
              this.value.rooms.push(aRoom)
            })
          }

        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors du chargement des pièces.")
        })
      }
    },

    /**
     * Création de la mission.
     */
    save() {
      this.$emit('save')
      this.close()
    }
  },

  mounted() {
    this.getLodgings(this.agency.id).then(() => {
      this.getActionsForLodging()
    }).catch(err => {
      console.log(err)
      this.displaySnackbarError("Erreur lors du chargement des logements.")
    })

  },

  i18n: MissionDialogI18n,

};