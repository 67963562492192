import HomappAgencyI18n from './HomappAgency.i18n.js'
import hHeader from './../../components/HHeader/HHeader.vue'
import hSnackbar from '@/components/HSnackbar/HSnackbar.vue';
import {
  mapActions,
  mapGetters
} from 'vuex'


export default {

  name: "HomappAgency",

  components: {
    hHeader,
    hSnackbar
  },

  props: {},

  computed: {
    ...mapGetters(['agency', 'user'])
  },

  data() {
    return {
      items: [{
          title: 'Mes logements',
          icon: 'mdi-home-city',
          page: 'lodgings'
        },
        {
          title: 'Planning',
          icon: 'mdi-calendar-blank',
          page: 'planning'
        },
        {
          title: 'Paramètres ménage',
          icon: 'mdi-silverware-clean',
          page: 'household-settings'
        },
        {
          title: 'Missions',
          icon: 'mdi-vacuum',
          page: 'missions'
        },
      ],
      adminItems: [
        {
          title: "Catalogue d'actions",
          icon: 'mdi-star',
          page: 'catalog-actions'
        },
        {
          title: "Catalogue de pièces",
          icon: 'mdi-sofa',
          page: 'catalog-rooms'
        },
        {
          title: 'Travailleurs',
          icon: 'mdi-account-group',
          page: 'workers-management'
        },
        {
          title: 'Agences',
          icon: 'mdi-store',
          page: 'agencies-management'
        },
      ],
      selectedItem: -1,
      route: this.$route.path
    };
  },

  watch: {
    selectedItem: {
      immediate: true,
      handler(val) {
        console.log("test")
        if (val == -1) {
          const currentRouteName = this.$route.name
          let indexToSelect = -1
          this.items.forEach((aItem, index) => {
            if (aItem.page === currentRouteName) {
              indexToSelect = index
            }
          })
          this.selectedItem = indexToSelect
        }
      }
    },
    '$route'(newRoute, oldRoute) {
      console.log('Nouvelle route:', newRoute.path);
      console.log('Ancienne route:', oldRoute.path);
      if (newRoute.path !== oldRoute.path) {
          let indexToSelect = -1
          this.items.forEach((aItem, index) => {
            if (newRoute.path.includes(aItem.page)) {
              indexToSelect = index
            }
          })
          this.selectedItem = indexToSelect
      }
    }
  },

  methods: {
    ...mapActions(['getAgencies', 'logout']),

    goToPage(pPage) {
      if (pPage) {
        this.$router.push('/' + pPage)
      } else {
        this.$router.push('/')
      }
    },

    logoutCurrentUser() {
      this.logout()
      this.$router.push('/')
    }
  },

  mounted() {
    this.getAgencies().then(() => {
      if (this.user.role === 'admin') {
        //this.displaySnackbarInfo("test.")
        this.adminItems.forEach(item => {
          this.items.push(item)
          if (this.selectedItem == -1) {
            const currentRouteName = this.$route.path
            let indexToSelect = -1
            this.items.forEach((aItem, index) => {
              if (currentRouteName.includes(aItem.page)) {
                indexToSelect = index
              }
            })
            this.selectedItem = indexToSelect
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  },

  i18n: HomappAgencyI18n,

};