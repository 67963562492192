import HomePageI18n from './HomePage.i18n.js';

export default {

  components: {},

  props: {},

  computed: {},

  data() {
    return {
      labels: [
        '7h',
        '8h',
        '9h',
        '10h',
        '11h',
        '12h',
        '13h',
        '14h',
      ],
      value: [
        10,
        20,
        10,
        30,
        10,
        10,
        2,
        30,
      ],
    };
  },

  watch: {},

  methods: {},

  mounted() {},

  i18n: HomePageI18n,

};
