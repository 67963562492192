import axios from 'axios'

const state = {
  companies: [],
  companiesLoading: false,
  companyUpdating: false,
  agencyCompanies: []
}

const actions = {
  /**
   * Get companies.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getCompanies({
    commit,
  }, ) {
    commit('setCompaniesLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/companies', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCompanies', response.data)
        commit('setCompaniesLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setCompaniesLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Get agency companies.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getAgencyCompanies({
    commit,
  }, agency) {
    commit('setCompaniesLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agency.id + '/companies', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setAgencyCompanies', response.data)
        commit('setCompaniesLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setCompaniesLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to create companies.
   * @param {*} param0 
   * @param {*} company 
   * @returns 
   */
  createCompany({
    commit,
  }, company) {
    return new Promise((resolve, reject) => {
      commit('setCompanyUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/companies', company, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCompanyUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCompanyUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to update companies.
   * @param {*} param0 
   * @param {*} company 
   * @returns 
   */
  updateCompany({
    commit,
  }, company) {
    return new Promise((resolve, reject) => {
      commit('setCompanyUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.patch(process.env.VUE_APP_API_URL + '/companies/' + company.id, company, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCompanyUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCompanyUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to delete company.
   * @param {*} param0 
   * @param {*} company 
   * @returns 
   */
  deleteCompany({
    commit,
  }, company) {
    return new Promise((resolve, reject) => {
      commit('setCompanyUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/companies/' + company.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCompanyUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCompanyUpdating', false)
        reject(error)
      })
    })
  },

}


const getters = {
  companies: state => state.companies,
  companiesLoading: state => state.companiesLoading,
  companyUpdating: state => state.companyUpdating,
  agencyCompanies: state => state.agencyCompanies

}

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies
  },
  setCompaniesLoading(state, companiesLoading) {
    state.companiesLoading = companiesLoading
  },
  setCompanyUpdating(state, companyUpdating) {
    state.companyUpdating = companyUpdating
  },
  setAgencyCompanies(state, agencyCompanies) {
    state.agencyCompanies = agencyCompanies
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}