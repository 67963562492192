import {
  mapActions,
  mapGetters
} from 'vuex';
import AgencyUsersI18n from './AgencyUsers.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['agency', 'users', 'usersLoading'])
  },

  data() {
    return {
      isValid: true,
      dialog: false,
      dialogDelete: false,
      itemToDelete: null,
      dialogResetPassword: false,
      itemToResetPassword: null,
      headers: [{
        text: "Prénom",
        value: 'firstname',
        sortable: true
      },
      {
        text: "Nom",
        value: 'lastname',
        sortable: true
      },
      {
        text: "Email",
        value: 'email',
        sortable: true
      },
      {
        text: "Role",
        value: 'role',
        sortable: true
      },
      {
        text: "Actif",
        value: 'is_active',
        sortable: true
      },
      {
        text: "Actions",
        value: 'actions',
      },
      ],
      newUser: {
        firstname: "",
        lastname: "",
        email: "",
        role: ""
      },
      defaultUser: {
        firstname: "",
        lastname: "",
        email: "",
        role: ""
      },
      roleList: [{
        value: "admin",
        text: "Administrateur"
      },
      {
        value: "agency-manager",
        text: "Gestionnaire d'agence"
      }
      ],
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      emailRules: [
        v => !!v || "L'email est requis",
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'email doit être valide"
      ],
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getAgencyUsers', 'createAgencyUser', 'deleteUser', 'forgotPassword', 'activeUser']),

    createItem() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.newUser = Object.assign({}, this.defaultUser)
      this.$refs.form.resetValidation()
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.itemToDelete = item
    },

    resetPasswordItem(item) {
      this.dialogResetPassword = true
      this.itemToResetPassword = item
    },

    closeDelete() {
      this.dialogDelete = false
      this.itemToDelete = null
    },

    closeResetPassword() {
      this.dialogResetPassword = false
      this.itemToResetPassword = null
    },

    activeItem(item) {
      const data = {
        agencyId: this.$route.params.agency_id,
        userId: item.id
      }
      this.activeUser(data).then(() => {
        this.loadUserList()
      }).catch(err => {
        console.log(err)
      })
    },

    confirmUserDelete() {
      const data = {
        agencyId: this.$route.params.agency_id,
        userId: this.itemToDelete.id
      }
      this.deleteUser(data).then(() => {
        this.loadUserList()
        this.dialogDelete = false
      }).catch(err => {
        console.log(err)
      })
    },

    confirmUserResetPassword() {
      const info = {
        email: this.itemToResetPassword.email
      }
      this.forgotPassword(info).then(() => {
        this.closeResetPassword()
      }).catch(err => {
        console.log(err)
      })
    },

    loadUserList() {
      this.getAgencyUsers(this.$route.params.agency_id).then(() => {
        this.newUser = Object.assign({}, this.defaultUser)
        this.dialog = false
      }).catch(err => {
        console.log(err)
        this.displaySnackbarError("Erreur lors de la récupération de la liste des utilisateurs.")
      })
    },

    createNewUser() {
      this.newUser.agencyId = this.$route.params.agency_id
      this.createAgencyUser(this.newUser).then(() => {
        this.displaySnackbarInfo("Utilisateur créé.")
        this.loadUserList()
      }).catch(err => {
        console.log(err)
      })
    }
  },

  mounted() {
    this.loadUserList()
  },

  i18n: AgencyUsersI18n,

};