import axios from 'axios'

const state = {
  lodgings: [],
  lodgingsLoading: false,
  lodgingUpdating: false,
  lodgingLoading: false,
}

const actions = {
  /**
   * Get lodgings.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getLodgings({
    commit,
  }, agencyId) {
    commit('setLodgingsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/lodgings', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setLodgings', response.data)
        commit('setLodgingsLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setLodgingsLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Find lodgings.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  fingLodging({
    commit,
  }, data) {
    commit('setLodgingLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + data.agencyId + '/lodgings/' + data.lodgingId, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setLodging', response.data)
        commit('setLodgingLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setLodgingLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Create lodging.
   * @param {*} param0 
   * @param {*} lodging 
   * @returns 
   */
  createLodging({
    commit,
  }, lodging) {
    return new Promise((resolve, reject) => {
      commit('setLodgingsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      const agencyId = lodging.get('agency_id')
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/lodgings', lodging, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setLodgingsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setLodgingsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Update lodging.
   * @param {*} param0 
   * @param {*} lodging 
   * @returns 
   */
  updateLodging({
    commit,
  }, lodging) {
    return new Promise((resolve, reject) => {
      commit('setLodgingsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      const agencyId = lodging.get('agency_id')
      const id = lodging.get('id')
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/lodgings/' + id, lodging, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setLodgingsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setLodgingsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Update lodging activation.
   * @param {*} param0 
   * @param {*} lodging 
   * @returns 
   */
  updateLodgingActivation({
    commit,
  }, lodging) {
    return new Promise((resolve, reject) => {
      commit('setLodgingsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      const agencyId = lodging.agency_id
      const id = lodging.id
      axios.put(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/lodgings/' + id + '/activation', lodging, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setLodgingsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setLodgingsUpdating', false)
        reject(error)
      })
    })
  },
}




const getters = {
  lodgings: state => state.lodgings,
  lodgingsLoading: state => state.lodgingsLoading,
  lodgingUpdating: state => state.lodgingUpdating,
  lodgingLoading: state => state.lodgingLoading
}

const mutations = {
  setLodgings(state, lodgings) {
    state.lodgings = lodgings
  },
  setLodgingsLoading(state, lodgingsLoading) {
    state.lodgingsLoading = lodgingsLoading
  },
  setLodgingsUpdating(state, lodgingUpdating) {
    state.lodgingUpdating = lodgingUpdating
  },
  setLodgingLoading(state, lodgingLoading) {
    state.lodgingLoading = lodgingLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}