import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import agencies from './modules/agencies'
import lodgings from './modules/lodgings'
import missions from './modules/missions'
import actions from './modules/actions'
import companies from './modules/companies'
import catalogActions from './modules/catalogActions'
import catalogRooms from './modules/catalogRooms'
import rooms from './modules/rooms'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    agencies,
    lodgings,
    missions,
    actions,
    companies,
    catalogActions,
    catalogRooms,
    rooms
  }
})