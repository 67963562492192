import {
  mapActions,
  mapGetters
} from 'vuex'
import moment from 'moment'
import WorkingMissionsI18n from './WorkingMissions.i18n.js'
import ActionsDialog from '@/components/ActionsDialog/ActionsDialog.vue'

export default {

  components: {
    ActionsDialog
  },

  props: {},

  computed: {
    ...mapGetters(['workingMissions', 'missionsLoading', 'agency']),
    formTitle() {
      return this.editedIndex === -1 ? 'Créer une mission' : 'Modifier une mission'
    },
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      footerProps: {
        'items-per-page-text': 'Missions par page',
      },
      headers: [{
          text: 'Code',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Logement',
          value: 'lodging.name'
        },
        {
          text: 'Tarif horaire',
          value: 'hourly_rate'
        },
        {
          text: 'Action',
          value: 'actions'
        },
        {
          text: 'Travailleur',
          value: 'company'
        },
        {
          text: 'Début de la mission',
          value: 'startMission'
        },
        {
          text: 'Actions',
          value: 'operations',
          sortable: false,
          align: 'center'
        },
      ],
      editedItem: {
        id: '',
        name: '',
        start: '',
        end: '',
        address: '',
        lodging: '',
      },
      defaultItem: {
        id: '',
        name: '',
        start: '',
        end: '',
        address: '',
        lodging: '',
      },
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    ...mapActions(['getWorkingMissions', 'updateMissionStatus']),
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validateMission(mission) {
      const status = {
        status: 'completed',
        agency_id: this.agency.id,
        lodging_id: mission.lodging_id,
        id: mission.id
      }
      this.updateMissionStatus(status).then(() => {
        this.displaySnackbarInfo("La mission a bien été mis à jour.")
        this.getWorkingMissions(this.agency.id).then(() => {}).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la récupération des missions.")
        })
      })
    },
    cancelMission(mission) {
      const status = {
        status: 'canceled',
        agency_id: this.agency.id,
        lodging_id: mission.lodging_id,
        id: mission.id
      }
      this.updateMissionStatus(status).then(() => {
        this.displaySnackbarInfo("La mission a bien été mis à jour.")
        this.getWorkingMissions(this.agency.id).then(() => {}).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la récupération des missions.")
        })
      })
    }
  },

  filters: {
    formatDate(pValue) {
      moment.locale('fr');
      let formattedDate = moment(pValue, "YYYY-MM-DD hh:mm:ss");
      return formattedDate.format('LLL');
    }
  },

  mounted() {
    this.getWorkingMissions(this.agency.id).then(() => {

    }).catch(err => {
      console.log(err)
      this.displaySnackbarError("Erreur lors de la récupération des missions.")
    })
  },

  i18n: WorkingMissionsI18n,

};