import WorkerDialogI18n from './WorkerDialog.i18n.js';

export default {

  components: {},

  props: {
    value: {
      type: Object,
      required: true
    },
    dialogOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formTitle() {
      return this.value.id == -1 ? 'Créer un travailleur' : 'Modifier un travailleur'
    },
    buttonTitle() {
      return this.value.id == -1 ? 'Créer' : 'Modifier'
    },
  },

  data() {
    return {
      dialog: false,
      isValid: true,
      nameRules: [
        v => !!v || 'La valeur est requise',
        v => (v && v.length <= 191) || 'La valeur doit faire moins de 191 caractères',
      ],
      phoneRules: [
        v => !!v || 'La valeur est requise',
        v => (v && v.length <= 12) || 'La valeur doit faire moins de 12 caractères',
      ],
      CPRules: [
        v => !!v || 'Le code postal est requis',
        v => (v && v.length <= 6) || 'Le code postal doit faire moins de 6 caractères',
      ],
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'email doit être valide"
      ],
      passwordRules: [
        v => !!v || 'Le mot de passe est requis',
        v => (v && v.length >= 5) || 'Le mot de passe doit faire plus de 5 caractères',
        v => /(?=.*[A-Z])/.test(v) || 'Le mot de passe doit contenir une majuscule',
        v => /(?=.*\d)/.test(v) || 'Le mot de passe doit contenir un nombre',
        v => /([!@$%])/.test(v) || 'Le mot de passe doit contenir un caractère spécial [!@#$%]'
      ]
    };
  },

  watch: {
    dialogOpen(val) {
      if (val) {
        this.$refs.form.resetValidation()
      }
      val || this.close()
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('save')
      this.close()
    }
  },

  mounted() {},

  i18n: WorkerDialogI18n,

};