import EventBus, {
    ACTIONS
} from '../eventBus/index'
export default {
    computed: {},
    data() {
        return {};
    },
    created: function () {},
    methods: {
        displaySnackbarInfo(pMessage) {
            const info = {
                color: 'primary',
                timeout: 5000,
                message: pMessage
            }
            EventBus.$emit(ACTIONS.SNACKBAR, info)
        },
        displaySnackbarError(pMessage) {
            const info = {
                color: 'error',
                timeout: 5000,
                message: pMessage
            }
            EventBus.$emit(ACTIONS.SNACKBAR, info)
        },
        displaySnackbarWarning(pMessage) {
            const info = {
                color: 'warning',
                timeout: 5000,
                message: pMessage
            }
            EventBus.$emit(ACTIONS.SNACKBAR, info)
        }
    }
};