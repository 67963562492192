import axios from 'axios'

const state = {
  rooms: [],
  roomsLoading: false,
  roomUpdating: false
}

const actions = {
  getLodgingRoomsAndActions({
    commit,
  }, lodgingId) {
    commit('setRoomsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      const params = { "withActions": true }
      axios.get(process.env.VUE_APP_API_URL + '/lodgings/' + lodgingId + '/rooms', {
        params: params,
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        response.data.forEach(room => {
          room.actions.forEach(action => {
            action.isSelected = true
          })
        })
        commit('setRooms', response.data)
        commit('setRoomsLoading', false)
        resolve(response)
      }).catch(error => {
        commit('setRoomsLoading', false)
        reject(error.response)
      })
    })
  },

  createRoom({
    commit,
  }, room) {
    return new Promise((resolve, reject) => {
      commit('setRoomUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + room.agency_id + '/lodgings/' + room.lodging_id + '/rooms', room, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setRoomUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setRoomUpdating', false)
        reject(error)
      })
    })
  },

  deleteRoom({
    commit,
  }, room) {
    return new Promise((resolve, reject) => {
      commit('setRoomUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/agencies/' + room.agency_id + '/lodgings/' + room.lodging_id + '/rooms/' + room.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setRoomUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setRoomUpdating', false)
        reject(error)
      })
    })
  },
}


const getters = {
  rooms: state => state.rooms,
  roomsLoading: state => state.roomsLoading,
  roomUpdating: state => state.roomUpdating
}

const mutations = {
  setRooms(state, rooms) {
    state.rooms = rooms
  },
  setRoomsLoading(state, roomsLoading) {
    state.roomsLoading = roomsLoading
  },
  setRoomUpdating(state, roomUpdating) {
    state.roomUpdating = roomUpdating
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}