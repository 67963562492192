import ActionsDialogI18n from './ActionsDialog.i18n.js';

export default {

  components: {},

  props: {
    rooms: {
      required: true,
      type: Array
    }
  },

  computed: {},

  data() {
    return {
      dialogAction: false
    };
  },

  watch: {},

  methods: {},

  mounted() {},

  i18n: ActionsDialogI18n,

};
