import {
  mapActions
} from 'vuex';
import RegisterConfirmationI18n from './RegisterConfirmation.i18n.js';

export default {

  components: {},

  props: {},

  computed: {},

  data() {
    return {
      uniqueCode: '',
      password1: '',
      password2: '',
      isValid: false,
      passwordEquals: false,
      show1: false,
      show2: false,
      rules: {
        required: value => !!value || 'Requis',
        min: v => v.length >= 8 || 'Minimum 8 caractères'
      },
    };
  },

  watch: {
    password1() {
      if (this.password1 !== this.password2) {
        this.passwordEquals = false
      } else {
        this.passwordEquals = true
      }
    },
    password2() {
      if (this.password1 !== this.password2) {
        this.passwordEquals = false
      } else {
        this.passwordEquals = true
      }
    }
  },

  methods: {
    ...mapActions(['resetPassword']),

    validatePassword() {
      const passwordInfo = {
        password: this.password1,
        uniqueCode: this.uniqueCode
      }
      this.resetPassword(passwordInfo).then(() => {
        this.$router.push('/')
      }).catch(err => {
        console.log(err)
      })
    }
  },

  mounted() {
    this.uniqueCode = this.$route.params.uniqueCode
  },

  i18n: RegisterConfirmationI18n,

};