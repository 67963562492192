import HLodgingI18n from './HLodging.i18n.js'
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {

  components: {},

  props: {
    lodging: {
      required: true,
      type: Object
    },
  },

  computed: {
    ...mapGetters(['agency', 'lodgingUpdating']),
    photoLink() {
      if (this.lodging.photo) {
        return this.lodging.photo.includes('https') ? this.lodging.photo : this.lodging.photoLink
      } else {
        return null
      }
    }
  },

  data() {
    return {
      currentLodgingUpdating: false
    };
  },

  watch: {},

  methods: {
    ...mapActions(['updateLodgingActivation']),

    editLodging() {
      this.$emit('editLodging')
    },
    toggleLodgingActivation() {
      this.currentLodgingUpdating = true
      const lodging = {
        id: this.lodging.id,
        agency_id: this.agency.id
      }
      this.updateLodgingActivation(lodging).catch(err => {
        console.log(err)
        this.lodging.is_active = !this.lodging.is_active
      }).finally(() => {
        this.currentLodgingUpdating = false
      })
    },
    goToDetails() {
      this.$router.push('household-settings/lodgings/' + this.lodging.id)
    }
  },

  mounted() {},

  i18n: HLodgingI18n,

};