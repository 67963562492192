import { render, staticRenderFns } from "./WorkersManagement.vue?vue&type=template&id=5ce28772&scoped=true&"
import script from "./WorkersManagement.js?vue&type=script&lang=js&"
export * from "./WorkersManagement.js?vue&type=script&lang=js&"
import style0 from "./WorkersManagement.scoped.scss?vue&type=style&index=0&id=5ce28772&prod&lang=scss&scoped=true&"
import style1 from "./WorkersManagement.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce28772",
  null
  
)

export default component.exports