import Vue from 'vue'
import VueRouter from 'vue-router'
import UserLogin from '../views/UserLogin/UserLogin.vue'
import HomappAgency from '@/views/HomappAgency/HomappAgency.vue'
import AgencyMissions from '@/views/AgencyMissions/AgencyMissions.vue'
import AgencyLodgings from '@/views/AgencyLodgings/AgencyLodgings.vue'
import OpenMissions from '@/views/OpenMissions/OpenMissions.vue'
import AssignedMissions from '@/views/AssignedMissions/AssignedMissions.vue'
import WorkingMissions from '@/views/WorkingMissions/WorkingMissions.vue'
import CompletedMissions from '@/views/CompletedMissions/CompletedMissions.vue'
import HomePage from '@/views/HomePage/HomePage.vue'
import WorkersManagement from '@/views/WorkersManagement/WorkersManagement.vue'
import AgencyPlanning from '@/views/AgencyPlanning/AgencyPlanning.vue'
import CatalogActions from '@/views/CatalogActions/CatalogActions.vue'
import CatalogRooms from '@/views/CatalogRooms/CatalogRooms.vue'
import AgenciesManagement from '@/views/AgenciesManagement/AgenciesManagement.vue'
import AgencyManagement from '@/views/AgencyManagement/AgencyManagement.vue'
import RegisterConfirmation from '@/views/RegisterConfirmation/RegisterConfirmation.vue'
import HouseholdSettings from '@/views/HouseholdSettings/HouseholdSettings.vue'
import HouseholdSettingsLodgings from '@/views/HouseholdSettings/HouseholdSettingsLodgings/HouseholdSettingsLodgings.vue'
import HouseholdSettingsLodgingConfiguration from '@/views/HouseholdSettings/HouseholdSettingsLodgingConfiguration/HouseholdSettingsLodgingConfiguration.vue'
import ForgetPassword from '@/views/ForgetPassword/ForgetPassword.vue'
import ResetMailSent from '@/views/ResetMailSent/ResetMailSent.vue'
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue'
import store from './../store'

Vue.use(VueRouter)

const routes = [

  // Public parts
  {
    path: '/login',
    name: 'login',
    component: UserLogin,
    meta: {
      notAllowedIfLogged: true
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/resetMailSent',
    name: 'resetMailSent',
    component: ResetMailSent
  },
  {
    path: '/resetPassword/:uniqueCode',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/confirmregister/:uniqueCode',
    name: 'confirmRegister',
    component: RegisterConfirmation,
    meta: {
      notAllowedIfLogged: true
    }
  },
  // Logged part
  {
    path: '/',
    component: HomappAgency,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: HomePage,
      },
      {
        path: 'missions',
        name: 'AgencyMissions',
        component: AgencyMissions,
        redirect: 'missions/open-missions',
        children: [{
          path: 'open-missions',
          name: 'OpenMissions',
          component: OpenMissions,
        },
        {
          path: 'assigned-missions',
          name: 'AssignedMissions',
          component: AssignedMissions,
        },
        {
          path: 'working-missions',
          name: 'WorkingMissions',
          component: WorkingMissions,
        },
        {
          path: 'completed-missions',
          name: 'CompletedMissions',
          component: CompletedMissions,
        }
      ]
      },
      {
        path: 'planning',
        name: 'planning',
        component: AgencyPlanning
      },
      {
        path: 'lodgings',
        name: 'AgencyLodgings',
        component: AgencyLodgings
      },
      {
        path: 'workers-management',
        name: 'WorkersManagement',
        component: WorkersManagement
      },
      {
        path: 'catalog-actions',
        name: 'CatalogActions',
        component: CatalogActions,
      },
      {
        path: 'catalog-rooms',
        name: 'CatalogRooms',
        component: CatalogRooms,
      },
      {
        path: 'agencies-management',
        name: 'AgenciesManagement',
        component: AgenciesManagement
      },
      {
        path: 'agencies-management/:agency_id',
        name: 'AgencyManagement',
        component: AgencyManagement
      },
      {
        path: 'household-settings',
        name: 'HouseholdSettings',
        component: HouseholdSettings,
        redirect: 'household-settings/lodgings',
        children: [
          {
            path: 'lodgings',
            name: 'HouseholdSettingsLodgings',
            component: HouseholdSettingsLodgings
          },
          {
            path: 'lodgings/:lodging_id',
            name: 'HouseholdSettingsLodgingConfiguration',
            component: HouseholdSettingsLodgingConfiguration
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if user is loggin
    if (localStorage.getItem('token')) {
      // Get info about the user
      store.dispatch('getUser').then(() => {
        next()
        return
      }).catch(() => {
        next('/login')
        return;
      })

    } else {
      next('/login')
    }
  } else if (to.matched.some(record => record.meta.notAllowedIfLogged) && localStorage.getItem('token')) {
    next('/')
    return
  } else {
    next();
  }
})

export default router