import AgencyMissionsI18n from './AgencyMissions.i18n.js';
import OpenMissions from '../OpenMissions/OpenMissions.vue';

export default {

  components: {OpenMissions},

  props: {},

  computed: {},

  data() {
    return {
      tab: null
    };
  },

  watch: {},

  methods: {
    goToPage(pPage) {
      this.$router.push('/'+ pPage)
    }
  },

  mounted() {},

  i18n: AgencyMissionsI18n,

};