import { render, staticRenderFns } from "./ResetMailSent.vue?vue&type=template&id=504c03c7&scoped=true&"
import script from "./ResetMailSent.js?vue&type=script&lang=js&"
export * from "./ResetMailSent.js?vue&type=script&lang=js&"
import style0 from "./ResetMailSent.scoped.scss?vue&type=style&index=0&id=504c03c7&prod&lang=scss&scoped=true&"
import style1 from "./ResetMailSent.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504c03c7",
  null
  
)

export default component.exports