import axios from 'axios'
const state = {
  agencies: [],
  agenciesLoading: false,
  agency: null,
  agencyLoading: false,
  editedAgency: null,
  agencyUpdating: false
}

const actions = {
  /**
   * Service to get all agencies.
   * @param {*} param0 
   * @returns 
   */
  getAgencies({
    commit,
  }) {
    commit('setAgenciesLoading', true)
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setAgencies', response.data)
        commit('setAgency', response.data[0])
        commit('setAgenciesLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setAgenciesLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to get one agency.
   * @param {*} param0 
   * @returns 
   */
  getAgency({
    commit,
  }, agencyId) {
    commit('setAgencyLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setEditedAgency', response.data)
        commit('setAgencyLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setAgencyLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to update agency
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  updateAgency({
    commit,
  }, agency) {
    return new Promise((resolve, reject) => {
      commit('setAgencyUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.patch(process.env.VUE_APP_API_URL + '/agencies/' + agency.id, agency, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setAgencyUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setAgencyUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to create agency
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  createAgency({
    commit,
  }, agency) {
    return new Promise((resolve, reject) => {
      commit('setAgencyUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/agencies', agency, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setAgencyUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setAgencyUpdating', false)
        reject(error)
      })
    })
  },
}


const getters = {
  agencies: state => state.agencies,
  agency: state => state.agency,
  agenciesLoading: state => state.agenciesLoading,
  agencyLoading: state => state.agencyLoading,
  editedAgency: state => state.editedAgency,
  agencyUpdating: state => state.agencyUpdating

}

const mutations = {
  setAgencies(state, agencies) {
    state.agencies = agencies
  },
  setAgency(state, agency) {
    state.agency = agency
  },
  setAgenciesLoading(state, agenciesLoading) {
    state.agenciesLoading = agenciesLoading
  },
  setAgencyLoading(state, agencyLoading) {
    state.agencyLoading = agencyLoading
  },
  setEditedAgency(state, editedAgency) {
    state.editedAgency = editedAgency
  },
  setAgencyUpdating(state, agencyUpdating) {
    state.agencyUpdating = agencyUpdating
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}