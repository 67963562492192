import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex';
import HHeaderI18n from './HHeader.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['agency', 'agencies'])
  },

  data() {
    return {
      drawer: false,
      mobile: false
    };
  },

  watch: {},

  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setAgency']),

    logoutCurrentUser() {
      this.logout().then(() => {
        location.reload()
      })
    },

    changeAgency(pAgency) {
      this.setAgency(pAgency)
    }
  },

  mounted() {},

  i18n: HHeaderI18n,

};