import HSnackbarI18n from './HSnackbar.i18n.js'
import EventBus, {
  ACTIONS
} from '../../eventBus/index'

export default {

  components: {},

  props: {},

  computed: {},

  data() {
    return {
      snackbar: false,
      snackbarTimeout: 5000,
      snackbarColor: 'primary',
      snackbarMessage: ''
    };
  },

  watch: {},

  methods: {},

  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, info => {
      this.snackbarMessage = info.message
      this.snackbarColor = info.color,
        this.snackbarTimeout = info.timeout
      this.snackbar = true
    })
  },

  i18n: HSnackbarI18n,

};