import {
  mapActions,
  mapGetters
} from 'vuex';
import AgencyLodgingsI18n from './AgencyLodgings.i18n.js'
import HLodging from '@/components/HLodging/HLodging.vue'
import LodgingDialog from '@/components/LodgingDialog/LodgingDialog.vue';

export default {

  components: {
    HLodging,
    LodgingDialog
  },

  props: {},

  computed: {
    ...mapGetters(['agency', 'lodgings', 'lodgingUpdating'])
  },

  data() {
    return {
      editedLodging: {
        name: '',
        address: '',
        CP: '',
        city: '',
        surface: 0,
        nbOfPersons: 1,
        photo: null,
        rooms: [],
        photoLink: '',
      },
      defaultLodging: {
        name: '',
        address: '',
        CP: '',
        city: '',
        surface: 0,
        nbOfPersons: 1,
        rooms: [],
        photo_file: null,
        photoLink: '',
      },
      dialog: false,
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      editingIndex: -1,
      actionsToSelect: [{
          header: "Saisissez le nom de l'action puis appuyez sur entrée pour l'ajouter"
        },
      ],
      nonce: 1,
      menu: false,
      x: 0,
      search: null,
      y: 0,

    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    'editedLodging.photo'() {
      this.editedLodging.photoLink = URL.createObjectURL(this.editedLodging.photo)
    },
    'editedLodging.actions'(val, prev) {
      if (val.length === prev.length) return
      this.editedLodging.actions = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          }
          this.actionsToSelect.push(v)
          this.nonce++
        }
        return v
      })
    },
  },

  methods: {
    ...mapActions(['getLodgings', 'createLodging', 'updateLodging']),

    /**
     * Fonction pour éditer un logement.
     * @param {*} lodging 
     */
    editLodging(lodging) {
      if (lodging) {
        this.editedLodging = Object.assign({}, lodging)
      }
      this.dialog = true
    },


    /**
     * Fermer la modale.
     */
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedLodging = Object.assign({}, this.defaultLodging);
      })
    },

    /**
     * Enregistrer le logement.
     */
    saveLodging() {
      const rooms = JSON.stringify(this.editedLodging.rooms)
      let lodging = new FormData();
      lodging.append('name', this.editedLodging.name)
      lodging.append('address', this.editedLodging.address)
      lodging.append('CP', this.editedLodging.CP)
      lodging.append('city', this.editedLodging.city)
      lodging.append('surface', this.editedLodging.surface)
      lodging.append('nbOfPersons', this.editedLodging.nbOfPersons)
      lodging.append('rooms', rooms)
      if (this.editedLodging.photo_file) {
        lodging.append('photo_file', this.editedLodging.photo_file)
      }
      lodging.append('agency_id', this.agency.id)
      if (this.editedLodging.id) {
        lodging.append('id', this.editedLodging.id)
        this.updateLodging(lodging).then(() => {
          this.loadLodgings()
          this.close()
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.createLodging(lodging).then(() => {
          this.displaySnackbarInfo("Le logement a bien été créé/modifié.")
          this.loadLodgings()
          this.close()
        }).catch(err => {
          this.displaySnackbarError("Erreur lors de l'édition du logement.")
          console.log(err)
        })
      }

    },

    /**
     * Load all lodgings.
     */
    loadLodgings() {
      this.getLodgings(this.agency.id).then(() => {

      }).catch(err => {
        console.log(err)
      })
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
  },

  mounted() {
    this.loadLodgings()
  },

  i18n: AgencyLodgingsI18n,

};