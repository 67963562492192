import axios from 'axios'

const state = {
  catalogActions: [],
  catalogActionsLoading: false,
  catalogActionsUpdating: false

}

const actions = {
  /**
   * Get catalog actions.
   * @param {*} param0 
   * @returns 
   */
  getCatalogActions({
    commit,
  }) {
    commit('setCatalogActionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/catalog_actions', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        response.data.map(catalogAction => catalogAction.catalog_id = catalogAction.id)
        commit('setCatalogActions', response.data)
        commit('setCatalogActionsLoading', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogActionsLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to create catalog action
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  createCatalogAction({
    commit,
  }, catalogAction) {
    return new Promise((resolve, reject) => {
      commit('setCatalogActionsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/catalog_actions', catalogAction, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogActionsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogActionsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to update catalog action
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  updateCatalogAction({
    commit,
  }, catalogAction) {
    return new Promise((resolve, reject) => {
      commit('setCatalogActionsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.patch(process.env.VUE_APP_API_URL + '/catalog_actions/' + catalogAction.id, catalogAction, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogActionsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogActionsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to delete catalog action
   * @param {*} param0 
   * @param {*} company 
   * @returns 
   */
  deleteCatalogAction({
    commit,
  }, catalogAction) {
    return new Promise((resolve, reject) => {
      commit('setCatalogActionsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/catalog_actions/' + catalogAction.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogActionsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogActionsUpdating', false)
        reject(error)
      })
    })
  },
}


const getters = {
  catalogActions: state => state.catalogActions,
  catalogActionsLoading: state => state.catalogActionsLoading,
  catalogActionsUpdating: state => state.catalogActionsUpdating,
}

const mutations = {
  setCatalogActions(state, catalogActions) {
    state.catalogActions = catalogActions
  },
  setCatalogActionsLoading(state, catalogActionsLoading) {
    state.catalogActionsLoading = catalogActionsLoading
  },
  setCatalogActionsUpdating(state, catalogActionsUpdating) {
    state.catalogActionsUpdating = catalogActionsUpdating
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}