import {
  mapActions,
  mapGetters
} from 'vuex';
import AgencyGeneralInformationsI18n from './AgencyGeneralInformations.i18n.js';

export default {

  components: {},

  props: {
    agencyId: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters(['editedAgency'])
  },

  data() {
    return {
      isValid: false,
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      phoneRules: [
        v => !!v || 'La valeur est requise',
        v => (v && v.length <= 12) || 'La valeur doit faire moins de 12 caractères',
      ],
      CPRules: [
        v => !!v || 'Le code postal est requis',
        v => (v && v.length <= 6) || 'Le code postal doit faire moins de 6 caractères',
      ],
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'email doit être valide"
      ],
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getAgency', 'updateAgency']),

    updateCurrentAgency() {
      this.updateAgency(this.editedAgency).then(() => {
        this.displaySnackbarInfo("Agence mise à jour.")
      }).catch(err => {
        console.log(err)
        this.displaySnackbarError("Erreur lors de la mise à jour de l'agence.")
      })
    }
  },

  mounted() {
    this.getAgency(this.agencyId).then(() => {}).catch(err => {
      console.log(err)
      this.displaySnackbarError("Erreur lors de la récupération des infos de l'agence.")
    })
  },

  i18n: AgencyGeneralInformationsI18n,

};