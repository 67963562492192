import { render, staticRenderFns } from "./HLodging.vue?vue&type=template&id=201fc1d5&scoped=true&"
import script from "./HLodging.js?vue&type=script&lang=js&"
export * from "./HLodging.js?vue&type=script&lang=js&"
import style0 from "./HLodging.scoped.scss?vue&type=style&index=0&id=201fc1d5&prod&lang=scss&scoped=true&"
import style1 from "./HLodging.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201fc1d5",
  null
  
)

export default component.exports