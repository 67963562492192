import axios from 'axios'

const state = {
  catalogRooms: [],
  catalogRoomsLoading: false,
  catalogRoomsUpdating: false

}

const actions = {
  /**
   * Get catalog rooms.
   * @param {*} param0 
   * @returns 
   */
  getCatalogRooms({
    commit,
  }) {
    commit('setCatalogRoomsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/catalog_rooms', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        response.data.map(catalogRoom => catalogRoom.catalog_id = catalogRoom.id)
        commit('setCatalogRooms', response.data)
        commit('setCatalogRoomsLoading', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogRoomsLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to create catalog room
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  createCatalogRoom({
    commit,
  }, catalogRoom) {
    return new Promise((resolve, reject) => {
      commit('setCatalogRoomsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/catalog_rooms', catalogRoom, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogRoomsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogRoomsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to update catalog room
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  updateCatalogRoom({
    commit,
  }, catalogRoom) {
    return new Promise((resolve, reject) => {
      commit('setCatalogRoomsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.patch(process.env.VUE_APP_API_URL + '/catalog_rooms/' + catalogRoom.id, catalogRoom, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogRoomsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogRoomsUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to delete catalog room
   * @param {*} param0 
   * @param {*} company 
   * @returns 
   */
  deleteCatalogRoom({
    commit,
  }, catalogRoom) {
    return new Promise((resolve, reject) => {
      commit('setCatalogRoomsUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/catalog_rooms/' + catalogRoom.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCatalogRoomsUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setCatalogRoomsUpdating', false)
        reject(error)
      })
    })
  },
}


const getters = {
  catalogRooms: state => state.catalogRooms,
  catalogRoomsLoading: state => state.catalogRoomsLoading,
  catalogRoomsUpdating: state => state.catalogRoomsUpdating,
}

const mutations = {
  setCatalogRooms(state, catalogRooms) {
    state.catalogRooms = catalogRooms
  },
  setCatalogRoomsLoading(state, catalogRoomsLoading) {
    state.catalogRoomsLoading = catalogRoomsLoading
  },
  setCatalogRoomsUpdating(state, catalogRoomsUpdating) {
    state.catalogRoomsUpdating = catalogRoomsUpdating
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}