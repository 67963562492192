import {
  mapActions,
  mapGetters
} from 'vuex'
import AssignDialogI18n from './AssignDialog.i18n.js';

export default {

  components: {},

  props: {
    missionId: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters(['agencyCompanies', 'agency'])
  },

  data() {
    return {
      dialog: false,
      selectedWorkerId: null
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getAgencyCompanies', 'assignMission']),

    closeDialog() {
      this.dialog = false
      this.selectedWorkerId = null
    },

    assignCurrentMission() {
      const data = {
        missionId: this.missionId,
        companyId: this.selectedWorkerId
      }
      this.assignMission(data).then(() => {
        this.$emit('reload')
        this.displaySnackbarInfo("La mission a bien été affectée.")
        this.closeDialog()
      }).catch(err => {
        console.log(err)
      })
    }
  },

  mounted() {
    this.getAgencyCompanies(this.agency).then(() => {}).catch(err => {
      console.log(err)
    })
  },

  i18n: AssignDialogI18n,

};