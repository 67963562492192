import {
  mapActions,
  mapGetters
} from 'vuex';
import CatalogRoomsI18n from './CatalogRooms.i18n.js'
import CatalogRoomDialog from '@/components/CatalogRoomDialog/CatalogRoomDialog.vue'

export default {

  components: {
    CatalogRoomDialog
  },

  props: {},

  computed: {
    ...mapGetters(['catalogRooms', 'catalogRoomsUpdating', 'catalogRoomsLoading']),
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [{
          text: 'Nom',
          align: 'start',
          value: 'name',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'operations'
        }
      ],
      editedItem: {
        name: '',
      },
      defaultItem: {
        id: -1,
        name: '',
      },
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getCatalogRooms', 'createCatalogRoom', 'updateCatalogRoom', 'deleteCatalogRoom']),

    editItem(item) {
      this.editedIndex = this.catalogRooms.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.catalogRooms.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItemConfirm() {
      this.deleteCatalogRoom(this.editedItem).then(() => {
        this.displaySnackbarInfo("L'action a bien été supprimée.")
        this.loadCatalogRooms()
      }).catch(err => {
        console.log(err)
      })
      this.closeDelete()
    },

    loadCatalogRooms() {
      this.getCatalogRooms().then(() => {

      }).catch(err => {
        console.log(err)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      this.dialog = false
      if (this.editedItem.id == -1) {
        this.createCatalogRoom(this.editedItem).then(() => {
          this.displaySnackbarInfo("L'action a bien été créée.")
          this.loadCatalogRooms()
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la mise à jour de l'action.")
        })
      } else {
        this.updateCatalogRoom(this.editedItem).then(() => {
          this.displaySnackbarInfo("L'action a bien été mise à jour.")
          this.loadCatalogRooms()
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la mise à jour de l'action.")
        })
      }
    }
  },

  mounted() {
    this.loadCatalogRooms()
  },

  i18n: CatalogRoomsI18n,

};