import { render, staticRenderFns } from "./CatalogActions.vue?vue&type=template&id=13effd40&scoped=true&"
import script from "./CatalogActions.js?vue&type=script&lang=js&"
export * from "./CatalogActions.js?vue&type=script&lang=js&"
import style0 from "./CatalogActions.scoped.scss?vue&type=style&index=0&id=13effd40&prod&lang=scss&scoped=true&"
import style1 from "./CatalogActions.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13effd40",
  null
  
)

export default component.exports