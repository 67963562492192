import {
  mapActions,
  mapGetters
} from 'vuex'
import moment from 'moment'
import OpenMissionsI18n from './OpenMissions.i18n.js'
import ActionsDialog from '@/components/ActionsDialog/ActionsDialog.vue'
import MissionDialog from '@/components/MissionDialog/MissionDialog.vue'
import AssignDialog from '@/components/AssignDialog/AssignDialog.vue'

export default {

  name: "OpenMissions",

  components: {
    ActionsDialog,
    MissionDialog,
    AssignDialog
  },

  props: {},

  computed: {
    ...mapGetters(['openMissions', 'missionsLoading', 'agency', 'lodgings']),
    formTitle() {
      return this.editedIndex === -1 ? 'Créer une mission' : 'Modifier une mission'
    },
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      footerProps: {
        'items-per-page-text': 'Missions par page',
      },
      headers: [{
          text: 'Code',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Logement',
          value: 'lodging.name'
        },
        {
          text: 'Titre',
          value: 'name'
        },
        {
          text: 'Tarif horaire',
          value: 'hourly_rate'
        },
        {
          text: 'Début de la mission',
          value: 'startMission'
        },
        {
          text: 'Début de publication',
          value: 'startRelease'
        },
        {
          text: 'Fin de publication',
          value: 'endRelease'
        },
        {
          text: 'Action',
          value: 'actions'
        },
        {
          text: '',
          value: 'operations',
          sortable: false,
          align: 'center'
        },
      ],
      editedItem: {
        id: -1,
        name: '',
        status: 'open',
        startRelease: null,
        endRelease: null,
        startMission: new Date(),
        address: '',
        lodging_id: '',
        duration: 0,
        hourly_rate: 10.00,
        actions: []
      },
      defaultItem: {
        id: -1,
        name: '',
        status: 'open',
        startRelease: null,
        endRelease: null,
        startMission: new Date(),
        address: '',
        lodging_id: '',
        duration: 0,
        hourly_rate: 10.00,
        actions: []
      },
      dialogAction: false
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    ...mapActions(['getOpenMissions', 'getLodgings', 'getActions', 'createMission', 'deleteMission', 'updateMission']),
    editItem(item) {
      console.log('rr')
      this.editedIndex = this.openMissions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.startMission = new Date(this.editedItem.startMission)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.openMissions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteMission(this.editedItem).then(() => {
        this.loadMissions()
        this.closeDelete()
        this.displaySnackbarInfo("La mission a bien été supprimée.")
      }).catch(err => {
        this.displaySnackbarError("Erreur lors de la suppression de la mission.")
        console.log(err)
        this.closeDelete()
      })
      
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    /**
     * Function to create mission.
     */
    save() {
      this.editedItem.agency_id = this.agency.id
      this.editedItem.startMission = moment(this.editedItem.startMission).format('YYYY-MM-DD HH:mm:ss')
      this.editedItem.rooms.forEach(room => {
        room.actions.forEach((action, index) => {
          if (!action.isSelected) {
            room.actions.splice(index, 1)
          }
        })
      })
      if (this.editedItem.id === -1) {
        this.createMission(this.editedItem).then(() => {
          this.displaySnackbarInfo("La mission a bien été créée.")
          this.loadMissions()
        }).catch(err => {
          this.displaySnackbarError("Erreur lors de la création de la mission.")
          console.log(err)
        })
      } else {
        this.updateMission(this.editedItem).then(() => {
          this.displaySnackbarInfo("La mission a bien été modifiée.")
          this.loadMissions()
        }).catch(err => {
          this.displaySnackbarError("Erreur lors de la mise à jour de la mission.")
          console.log(err)
        })
      }
    },
    loadMissions() {
      this.getOpenMissions(this.agency.id).then(() => {
      }).catch(err => {
        console.log(err)
        this.displaySnackbarError("Erreur lors du chargement des missions.")
      })
    },
  },

  filters: {
    formatDate(pValue) {
      moment.locale('fr')
      let formattedDate = moment(pValue, "YYYY-MM-DD hh:mm:ss")
      return formattedDate.format('LL')
    }
  },

  mounted() {
    this.loadMissions()
  },

  i18n: OpenMissionsI18n,

};