import HLodgingMiniI18n from './HLodgingMini.i18n.js';

export default {

  components: {},

  props: {
    lodging: {
      required: true,
      type: Object
    },
  },

  computed: {
    photoLink() {
      if (this.lodging.photo) {
        return this.lodging.photo.includes('https') ? this.lodging.photo : this.lodging.photoLink
      } else {
        return null
      }
    }
  },

  data() {
    return {};
  },

  watch: {},

  methods: {},

  mounted() {},

  i18n: HLodgingMiniI18n,

};
