import { mapActions, mapGetters } from 'vuex';
import LodgingDialogI18n from './LodgingDialog.i18n.js';

export default {

  components: {},

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['catalogActions', 'catalogRooms']),
    buttonTitle() {
      return this.value.id > 0 ? "Mettre à jour" : "Créer"
    },
    isUpdating() {
      return this.value.id > 0
    },
    roomAlreadyAdded() {
      return this.value.rooms.find(room => room.catalog_id === this.currentRoom)
    },
  },

  data() {
    return {
      e1: 1,
      selectedCatalogActions: [],
      step1Valid: true,
      newRoom: null,
      currentRoom: null,
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      addressRules: [
        v => !!v || "L'adresse est requise",
        v => (v && v.length <= 200) || "L'adresse doit faire moins de 200 caractères",
      ],
      CPRules: [
        v => !!v || "Le code postal est requis",
        v => (v && v.length <= 10) || "Le code postal doit faire moins de 6 caractères",
      ],
      cityRules: [
        v => !!v || "La ville est requise",
        v => (v && v.length <= 50) || "La ville doit faire moins de 50 caractères",
      ],
      surfaceRules: [
        v => !!v || "La surface est requise",
        v => (v && v > 1) || "La surface doit faire plus de 1m²",
      ],
      nbPersonsRules: [
        v => !!v || "Le nombre de personnes est requis",
        v => (v && v >= 1) || "Le nombre de personne doit être supérieur à 0",
      ],
    };
  },

  watch: {
    'value.rooms'() {}
  },

  methods: {
    ...mapActions(['getCatalogActions', 'getCatalogRooms']),

    init() {
    },

    close() {
      this.e1 = 1
      this.$emit('close')
    },

    save() {
      this.$emit('save')
      this.e1 = 1
    },

    getCatalogAction(name) {
      let catalogAction = null
      this.catalogActions.forEach(aCatalogAction => {
        if (aCatalogAction.name === name) {
          catalogAction = Object.assign({}, aCatalogAction)
        }
      })
      return catalogAction
    },

    addActionToRoom(room, action) {
      room.actions.push(action)
    },

    removeActionFromRoom(room, action) {
      const index = room.actions.indexOf(action.id)
      room.actions.splice(index, 1)
    },

    addRoom() {
      let roomToAdd = this.catalogRooms.find(room => room.id == this.currentRoom)
      roomToAdd.actions = []
      this.value.rooms.push(roomToAdd)
      this.currentRoom = null
    }
  },

  mounted() {
    this.getCatalogActions().then(() => {
      this.getCatalogRooms().then(() => {
        this.init()
      })
    }).catch(err => {
      console.log(err)
      this.displaySnackbarError("Erreur lors du chargement des actions.")
    })
  },

  i18n: LodgingDialogI18n,

};