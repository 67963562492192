import HouseholdSettingsLodgingsI18n from './HouseholdSettingsLodgings.i18n.js';
import HLodgingMini from '@/components/HLodgingMini/HLodgingMini.vue';
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {

  components: {
    HLodgingMini
  },

  props: {},

  computed: {
    ...mapGetters(['agency', 'lodgings', 'lodgingUpdating'])
  },

  data() {
    return {};
  },

  watch: {},

  methods: {
    ...mapActions(['getLodgings']),

    loadLodgings() {
      this.getLodgings(this.agency.id).then(() => {

      }).catch(err => {
        console.log(err)
      })
    },

    goToLodging(pLodgingId) {
      console.log("test")
      this.$router.push('lodgings/' + pLodgingId)
    }
  },

  mounted() {
    this.loadLodgings()
  },

  i18n: HouseholdSettingsLodgingsI18n,

};
