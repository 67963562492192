import {
  mapActions,
  mapGetters
} from 'vuex';
import CatalogActionsI18n from './CatalogActions.i18n.js'
import CatalogActionDialog from '@/components/CatalogActionDialog/CatalogActionDialog.vue'

export default {

  components: {
    CatalogActionDialog
  },

  props: {},

  computed: {
    ...mapGetters(['catalogActions', 'catalogActionsUpdating', 'catalogActionsLoading']),
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [{
          text: 'Nom',
          align: 'start',
          value: 'name',
          sortable: true
        },
        {
          text: 'Action en option',
          value: 'is_option',
        },
        {
          text: 'Actions',
          value: 'operations'
        }
      ],
      editedItem: {
        name: '',
        is_option: false,
      },
      defaultItem: {
        id: -1,
        name: '',
        is_option: false,
      },
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getCatalogActions', 'createCatalogAction', 'updateCatalogAction', 'deleteCatalogAction']),

    editItem(item) {
      this.editedIndex = this.catalogActions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.catalogActions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItemConfirm() {
      this.deleteCatalogAction(this.editedItem).then(() => {
        this.displaySnackbarInfo("L'action a bien été supprimée.")
        this.loadCatalogActions()
      }).catch(err => {
        console.log(err)
      })
      this.closeDelete()
    },

    loadCatalogActions() {
      this.getCatalogActions().then(() => {

      }).catch(err => {
        console.log(err)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      this.dialog = false
      if (this.editedItem.id == -1) {
        this.createCatalogAction(this.editedItem).then(() => {
          this.displaySnackbarInfo("L'action a bien été créée.")
          this.loadCatalogActions()
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la mise à jour de l'action.")
        })
      } else {
        this.updateCatalogAction(this.editedItem).then(() => {
          this.displaySnackbarInfo("L'action a bien été mise à jour.")
          this.loadCatalogActions()
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la mise à jour de l'action.")
        })
      }
    }
  },

  mounted() {
    this.loadCatalogActions()
  },

  i18n: CatalogActionsI18n,

};