import { render, staticRenderFns } from "./HLodgingMini.vue?vue&type=template&id=16d670d0&scoped=true&"
import script from "./HLodgingMini.js?vue&type=script&lang=js&"
export * from "./HLodgingMini.js?vue&type=script&lang=js&"
import style0 from "./HLodgingMini.scoped.scss?vue&type=style&index=0&id=16d670d0&prod&lang=scss&scoped=true&"
import style1 from "./HLodgingMini.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d670d0",
  null
  
)

export default component.exports