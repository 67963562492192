import {
  mapActions,
  mapGetters
} from 'vuex'
import moment from 'moment'
import AgencyPlanningI18n from './AgencyPlanning.i18n.js'

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['agency', 'assignedMissions', 'openMissions'])
  },

  data() {
    return {
      focus: '',
      weekDay: [1, 2, 3, 4, 5, 6, 0],
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getWorkingMissions', 'getOpenMissions']),

    setToday() {
      this.focus = ''
    },

    loadWorkingMissions() {
      this.getWorkingMissions(this.agency.id).then(() => {
        this.getOpenMissions(this.agency.id).then(() => {
          this.getEvents()
        })
      }).catch(err => {
        console.log(err)
      })
    },

    getEvents() {
      this.events = []
      this.assignedMissions.forEach(mission => {
        moment.locale('fr')
        const start = moment(mission.startMission, 'YYYY-MM-DD hh:mm:ss').unix() * 1000
        const startMissionDate = moment(mission.startMission, 'YYYY-MM-DD').format('LL')
        const end = start + mission.duration
        const startTime = moment(start).format("HH:mm")
        const endTime = moment(end).format("HH:mm")
        const event = {
          name: mission.name,
          start: start,
          end: end,
          color: 'green',
          lodgingName: mission.lodging.name,
          lodgingAddress: mission.lodging.address + ' ' + mission.lodging.CP + ' ' + mission.lodging.city,
          status: mission.status,
          companyName: mission.company.name,
          startDate: startMissionDate,
          startTime: startTime,
          endTime: endTime,
          timed: true,
          actions: mission.actions
        }
        this.events.push(event)
      })
      this.openMissions.forEach(mission => {
        const start = moment(mission.startMission, 'YYYY-MM-DD hh:mm:ss').unix() * 1000
        const startMissionDate = moment(mission.startMission, 'YYYY-MM-DD').format('LL')
        let duration = 0
        mission.actions.forEach(action => {
          duration = duration + (action.duration * 60 * 1000)
        })
        const end = start + duration
        const startTime = moment(start).format("HH:mm")
        const endTime = moment(end).format("HH:mm")
        const event = {
          name: mission.name,
          start: start,
          end: end,
          color: 'orange',
          lodgingName: mission.lodging.name,
          lodgingAddress: mission.lodging.address + ' ' + mission.lodging.CP + ' ' + mission.lodging.city,
          status: mission.status,
          startDate: startMissionDate,
          startTime: startTime,
          endTime: endTime,
          timed: true,
          actions: mission.actions
        }
        this.events.push(event)
      })
    },

    showEvent({
      nativeEvent,
      event
    }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },


  mounted() {
    this.loadWorkingMissions()
    this.getEvents()
  },

  i18n: AgencyPlanningI18n,

};