import ResetMailSentI18n from './ResetMailSent.i18n.js';

export default {

  components: {},

  props: {},

  computed: {},

  data() {
    return {};
  },

  watch: {},

  methods: {
    goToLogin() {
      this.$router.push('/')
    }
  },

  mounted() {},

  i18n: ResetMailSentI18n,

};
