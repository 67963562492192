import { render, staticRenderFns } from "./RegisterConfirmation.vue?vue&type=template&id=8887d4b4&scoped=true&"
import script from "./RegisterConfirmation.js?vue&type=script&lang=js&"
export * from "./RegisterConfirmation.js?vue&type=script&lang=js&"
import style0 from "./RegisterConfirmation.scoped.scss?vue&type=style&index=0&id=8887d4b4&prod&lang=scss&scoped=true&"
import style1 from "./RegisterConfirmation.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8887d4b4",
  null
  
)

export default component.exports