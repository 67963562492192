import { mapActions, mapGetters } from 'vuex';
import UserLoginI18n from './UserLogin.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['loggingLoading'])
  },

  data() {
    return {
      show1: false,
      error: '',
      login: '',
      password: ''
    };
  },

  watch: {},

  methods: {
    ...mapActions(['loggin', 'getUser']),
    submit() {
      if (this.login !== '' && this.password !== '') {
        const user = {
          email: this.login,
          password: this.password
        };
        this.loggin(user).then(() => {
          this.$router.push('/')
        }).catch(err => {
          console.log(err)
          this.error = "Email ou mot de passe incorrect"
        })
      } else {
        this.error = "L'email ou le mot de passe ne doit pas être vide"
      }
    },
    goToForgotPassword() {
      this.$router.push('/forgetPassword')
    }

  },

  mounted() {
    console.log(process.env.VUE_APP_API_URL)
    this.getUser()
  },

  i18n: UserLoginI18n,

};
