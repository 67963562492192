import HouseholdSettingsI18n from './HouseholdSettings.i18n.js'
export default {

  components: {},

  props: {},

  computed: {
  },

  data() {
    return {};
  },

  watch: {},

  methods: {},

  mounted() {},

  i18n: HouseholdSettingsI18n,

};
