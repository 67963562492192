import {
  mapActions,
  mapGetters
} from 'vuex';
import WorkersManagementI18n from './WorkersManagement.i18n.js'
import WorkerDialog from '@/components/WorkerDialog/WorkerDialog.vue'

export default {

  components: {
    WorkerDialog
  },

  props: {},

  computed: {
    ...mapGetters(['companies'])
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [{
          text: 'Nom',
          align: 'start',
          value: 'name',
        },
        {
          text: 'N° siret',
          value: 'siret'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: true
        },
        {
          text: 'Téléphone',
          value: 'phone',
          sortable: false
        },
        {
          text: 'Adresse',
          value: 'address_total',
          sortable: false
        },
        {
          text: '',
          value: 'operations',
          sortable: false,
          align: 'center'
        },
      ],
      editedIndex: -1,
      editedItem: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        CP: '',
        city: '',
        name: '',
        siret: ''
      },
      defaultItem: {
        id: -1,
        firstname: '',
        lastname: '',
        password: '',
        email: '',
        phone: '',
        address: '',
        CP: '',
        city: '',
        name: '',
        siret: ''
      },
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getCompanies', 'createCompany', 'updateCompany', 'deleteCompany']),

    createItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.editedIndex = this.companies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedItem.id === -1) {
        this.createCompany(this.editedItem).then(() => {
          this.displaySnackbarInfo("Le travailleur a bien été créé.")
          this.getCompanies().then(() => {}).catch(err => {
            console.log(err)
            this.displaySnackbarError("Erreur lors de la récupération des travailleurs.")
          })
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la création du travailleur.")
        })
      } else {
        this.updateCompany(this.editedItem).then(() => {
          this.displaySnackbarInfo("Le travailleur a bien été mis à jour.")
          this.getCompanies().then(() => {}).catch(err => {
            this.displaySnackbarInfo("Erreur lors de la récupération des travailleurs.")
            console.log(err)
          })
        }).catch(err => {
          console.log(err)
          this.displaySnackbarError("Erreur lors de la mise à jour du travailleur.")
        })
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItem(item) {
      this.editedIndex = this.companies.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteCompany(this.editedItem).then(() => {
        this.getCompanies().then(() => {}).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
      })
      this.closeDelete()
    },

  },

  mounted() {
    this.getCompanies().then(() => {

    }).catch(err => {
      console.log(err)
    })
  },

  i18n: WorkersManagementI18n,

};