import { mapActions, mapGetters } from 'vuex';
import HouseholdSettingsLodgingConfigurationI18n from './HouseholdSettingsLodgingConfiguration.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['agency', 'rooms', 'roomsLoading', 'catalogRooms', 'actions', 'catalogActions']),
  },

  data() {
    return {
      selectedRoomIndex: 0,
      selectedActionId: 0,
      roomToDeleteId: 0,
      actionToDeleteId: 0,
      dialogRoom: false,
      dialogDeleteRoom: false,
      dialogDeleteAction: false,
      dialogAction: false,
      currentRoom: null,
      isRoomValid: true,
      isActionValid: true,
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      editedRoom: {
        catalog_room_id: ""
      },
      defaultRoom: {
        catalog_room_id: ""
      },
      editedAction: {
        catalog_action_id: ""
      },
      defaultAction: {
        catalog_action_id: ""
      },
      
    };
  },

  watch: {
    selectedRoomIndex(val) {
      this.currentRoom = this.rooms[val]
      this.loadActions()
    },
  },

  methods: {
    ...mapActions(['getLodgingRoomsAndActions', 'createRoom', 'getCatalogRooms', 'deleteRoom', 'getActionsByRoom', 'getCatalogActions', 'createAction', 'deleteAction']),

    goBackToList() {
      this.$router.push('/household-settings')
    },

    loadLodgingRoomsAndActions() {
      this.getLodgingRoomsAndActions(parseInt(this.$route.params.lodging_id)).then(() => {
        this.selectedRoomId = this.rooms[0].id
        this.loadCatalogRooms()
        this.loadCatalogActions()
      }).catch(err => {
        this.displaySnackbarError("Impossible de charger les pièces")
        console.log(err)
      })
    },

    loadActions() {
      const data = {
        id: this.currentRoom.id,
        lodging_id: this.$route.params.lodging_id
      }
      this.getActionsByRoom(data).then(() => {
      }).catch(err => {
        this.displaySnackbarError("Impossible de charger les actions")
        console.log(err)
      })
    },

    loadCatalogRooms() {
      this.getCatalogRooms().then(() => {

      }).catch(err => {
        this.displaySnackbarError("Impossible de charger le catalogue de pièces")
        console.log(err)
      })
    },

    loadCatalogActions() {
      this.getCatalogActions().then(() => {

      }).catch(err => {
        this.displaySnackbarError("Impossible de charger le catalogue d'actions")
        console.log(err)
      })
    },

    createNewRoom() {
      const room = {
        catalog_room_id: this.editedRoom.catalog_room_id,
        agency_id: this.agency.id,
        lodging_id: this.$route.params.lodging_id
      }
      this.createRoom(room).then(() => {
        this.displaySnackbarInfo("Pièce créée")
        this.closeRoomDialog()
        this.loadLodgingRoomsAndActions()
      }).catch(err => {
        this.displaySnackbarError("Impossible de créer la pièce")
        console.log(err)
      })
    },

    createNewAction() {
      const action = {
        catalog_action_id: this.editedAction.catalog_action_id,
        agency_id: this.agency.id,
        lodging_id: this.$route.params.lodging_id,
        room_id: this.currentRoom.id
      }
      this.createAction(action).then(() => {
        this.displaySnackbarInfo("Action créée")
        this.closeActionDialog()
        this.loadActions()
      }).catch(err => {
        this.displaySnackbarError("Impossible de créer l'action")
        console.log(err)
      })
    },

    openRoomDialog() {
      this.editedRoom = Object.assign({}, this.defaultRoom)
      this.dialogRoom = true
    },
    closeRoomDialog() {
      this.dialogRoom = false
    },
    showDeleteRoomDialog(roomToDeleteId) {
      this.roomToDeleteId = roomToDeleteId
      this.dialogDeleteRoom = true
    },
    closeDeleteRoomDialog() {
      this.dialogDeleteRoom = false
    },
    deleteRoomConfirm() {
      const room = {
        id: this.roomToDeleteId,
        agency_id: this.agency.id,
        lodging_id: this.$route.params.lodging_id
      }
      this.deleteRoom(room).then(() => {
        this.displaySnackbarInfo("Pièce supprimée")
        this.closeDeleteRoomDialog()
        this.currentRoom = this.rooms[0]
        this.loadLodgingRoomsAndActions()
      }).catch(err => {
        this.displaySnackbarError("Impossible de supprimer la pièce")
        console.log(err)
      })
    },

    openActionDialog() {
      this.editedAction = Object.assign({}, this.defaultAction)
      this.dialogAction = true
    },
    closeActionDialog() {
      this.dialogAction = false
    },
    showDeleteActionDialog(actionToDeleteId) {
      this.actionToDeleteId = actionToDeleteId
      this.dialogDeleteAction = true
    },
    closeDeleteActionDialog() {
      this.dialogDeleteAction = false
    },
    deleteActionConfirm() {
      const action = {
        id: this.actionToDeleteId,
        room_id: this.currentRoom.id,
        agency_id: this.agency.id,
        lodging_id: this.$route.params.lodging_id
      }
      this.deleteAction(action).then(() => {
        this.displaySnackbarInfo("Action supprimée")
        this.closeDeleteActionDialog()
        this.currentAction = this.actions[0]
        this.loadActions()
      }).catch(err => {
        this.displaySnackbarError("Impossible de supprimer l'action")
        console.log(err)
      })
    }
  },

  mounted() {
    this.loadLodgingRoomsAndActions()
  },

  i18n: HouseholdSettingsLodgingConfigurationI18n,

};
