import CatalogRoomDialogI18n from './CatalogRoomDialog.i18n.js';

export default {

  components: {},

  props: {
    value: {
      type: Object,
      required: true
    },
    dialogOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formTitle() {
      return this.value.id == -1 ? "Créer un modèle de pièce" : "Modifier un modèle de pièce"
    },
    buttonTitle() {
      return this.value.id == -1 ? "Créer" : "Modifier"
    },
  },

  data() {
    return {
      isValid: true,
      dialog: false,
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
    };
  },

  watch: {
    dialogOpen(val) {
      if (val) {
        this.$refs.form.resetValidation()
      }
      val || this.close()
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    save() {
      this.$emit('save')
      this.close()
    }
  },

  mounted() {},

  i18n: CatalogRoomDialogI18n,

};