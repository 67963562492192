import {
  mapActions,
  mapGetters
} from 'vuex';
import AgenciesManagementI18n from './AgenciesManagement.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['agencies'])
  },

  data() {
    return {
      dialog: false,
      isValid: true,
      headers: [{
          text: "Nom de l'agence",
          value: 'name',
          sortable: true
        },
        {
          text: "Actions",
          value: 'actions',
        },
      ],
      nameRules: [
        v => !!v || 'Le nom est requis',
        v => (v && v.length <= 191) || 'Le nom doit faire moins de 191 caractères',
      ],
      phoneRules: [
        v => !!v || 'La valeur est requise',
        v => (v && v.length <= 12) || 'La valeur doit faire moins de 12 caractères',
      ],
      CPRules: [
        v => !!v || 'Le code postal est requis',
        v => (v && v.length <= 6) || 'Le code postal doit faire moins de 6 caractères',
      ],
      emailRules: [
        v => !!v || "L'email est requis",
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'email doit être valide"
      ],
      newAgency: {
        name: '',
        address: '',
        CP: '',
        city: '',
        phone: '',
        email: ''
      },
      defaultAgency: {
        name: '',
        address: '',
        CP: '',
        city: '',
        phone: '',
        email: ''
      }
    };
  },

  watch: {},

  methods: {
    ...mapActions(['getAgencies', 'createAgency']),
    goToEdit(agency) {
      this.$router.push('/agencies-management/' + agency.id)
    },

    closeDialog() {
      this.dialog = false
      this.newAgency = Object.assign({}, this.defaultAgency)
    },

    createItem() {
      this.newAgency = Object.assign({}, this.defaultAgency)
      this.dialog = false
      this.dialog = true
    },

    createCurrentAgency() {
      this.createAgency(this.newAgency).then(() => {
        this.dialog = false
        this.getAgencies().then(() => {
          this.displaySnackbarInfo("L'agence a bien été créée.")
          this.snackbar = true
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
        this.displaySnackbarError("Erreur lors de la création de l'agence.")
      })
    }
  },

  mounted() {
    this.getAgencies().then(() => {}).catch(err => {
      console.log(err)
    })
  },

  i18n: AgenciesManagementI18n,

};