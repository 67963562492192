import axios from 'axios'

const state = {
  openMissions: [],
  assignedMissions: [],
  workingMissions: [],
  completedMissions: [],
  missionsLoading: false,
  missionUpdating: false

}

const actions = {
  /**
   * Service to get missions.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getOpenMissions({
    commit,
  }, agencyId) {
    commit('setMissionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/missions?status=open&lodging_active=true', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        response.data.forEach(aMission => {
          aMission.rooms.forEach(aRoom => {
            aRoom.actions = []
            const action = aMission.actions.find(aAction => aAction.room_id === aRoom.id)
            if (action) {
              aRoom.actions.push(action)
            }
          })
        })
        commit('setOpenMissions', response.data)
        commit('setMissionsLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setMissionsLoading', false)
        reject(error.response)
      })
    })
  },
  /**
   * Service to get assigned missions.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getAssignedMissions({
    commit,
  }, agencyId) {
    commit('setMissionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/missions?status=assigned', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setAssignedMissions', response.data)
        commit('setMissionsLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setMissionsLoading', false)
        reject(error.response)
      })
    })
  },
  /**
   * Service to get working missions.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getWorkingMissions({
    commit,
  }, agencyId) {
    commit('setMissionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/missions?status=working', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setWorkingMissions', response.data)
        commit('setMissionsLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setMissionsLoading', false)
        reject(error.response)
      })
    })
  },
  /**
   * Service to get completed missions.
   * @param {*} param0 
   * @param {*} agencyId 
   * @returns 
   */
  getCompletedMissions({
    commit,
  }, agencyId) {
    commit('setMissionsLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/missions?status=completed,payed', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setCompletedMissions', response.data)
        commit('setMissionsLoading', false)
        resolve(response);
      }).catch(error => {
        commit('setMissionsLoading', false)
        reject(error.response)
      })
    })
  },

  /**
   * Service to create missions.
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  createMission({
    commit,
  }, mission) {
    return new Promise((resolve, reject) => {
      commit('setMissionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + mission.agency_id + '/lodgings/' + mission.lodging_id + '/missions', mission, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setMissionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setMissionUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to assign missions.
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
   assignMission({
    commit,
  }, data) {
    return new Promise((resolve, reject) => {
      commit('setMissionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/companies/' + data.companyId + '/missions/' + data.missionId, {}, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setMissionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setMissionUpdating', false)
        reject(error)
      })
    })
  },

  /**
   * Service to update mission.
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  updateMission({
    commit,
  }, mission) {
    return new Promise((resolve, reject) => {
      commit('setMissionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.put(process.env.VUE_APP_API_URL + '/agencies/' + mission.agency_id + '/lodgings/' + mission.lodging_id + '/missions/' + mission.id, mission, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setMissionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setMissionUpdating', false)
        reject(error)
      })
    })
  },

   /**
   * Service to update mission status.
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
   updateMissionStatus({
    commit,
  }, status) {
    return new Promise((resolve, reject) => {
      commit('setMissionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.put(process.env.VUE_APP_API_URL + '/agencies/' + status.agency_id + '/lodgings/' + status.lodging_id + '/missions/' + status.id + '/status', status, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setMissionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setMissionUpdating', false)
        reject(error)
      })
    })
  },


  /**
   * Service to delete mission.
   * @param {*} param0 
   * @param {*} mission 
   * @returns 
   */
  deleteMission({
    commit,
  }, mission) {
    return new Promise((resolve, reject) => {
      commit('setMissionUpdating', true)
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/agencies/' + mission.agency_id + '/lodgings/' + mission.lodging_id + '/missions/' + mission.id, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setMissionUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setMissionUpdating', false)
        reject(error)
      })
    })
  },

}


const getters = {
  openMissions: state => state.openMissions,
  assignedMissions: state => state.assignedMissions,
  workingMissions: state => state.workingMissions,
  completedMissions: state => state.completedMissions,
  missionsLoading: state => state.missionsLoading,
  missionUpdating: state => state.missionUpdating
}

const mutations = {
  setOpenMissions(state, openMissions) {
    state.openMissions = openMissions
  },
  setAssignedMissions(state, assignedMissions) {
    state.assignedMissions = assignedMissions
  },
  setWorkingMissions(state, workingMissions) {
    state.workingMissions = workingMissions
  },
  setCompletedMissions(state, completedMissions) {
    state.completedMissions = completedMissions
  },
  setMissionsLoading(state, missionsLoading) {
    state.missionsLoading = missionsLoading
  },
  setMissionUpdating(state, missionUpdating) {
    state.missionUpdating = missionUpdating
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}