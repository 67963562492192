import AgencyManagementI18n from './AgencyManagement.i18n.js'
import AgencyGeneralInformations from '@/components/AgencyGeneralInformations/AgencyGeneralInformations.vue'
import AgencyUsers from '@/components/AgencyUsers/AgencyUsers.vue'

export default {

  components: {
    AgencyGeneralInformations,
    AgencyUsers
  },

  props: {},

  computed: {},

  data() {
    return {
      tab: 0,
      items: [{
          tab: 'general',
          text: 'Informations générales'
        },
        {
          tab: 'users',
          text: 'Utilisateurs'
        },
      ],
      agencyId: null
    };
  },

  watch: {},

  methods: {
    goBackToList() {
      this.$router.push('/agencies-management')
    }
  },

  mounted() {
    this.agencyId = parseInt(this.$route.params.agency_id)
  },

  i18n: AgencyManagementI18n,

};