import axios from 'axios'

const state = {
  user: null,
  loggingLoading: false,
  users: [],
  usersLoading: false,
  userUpdating: false
}

const actions = {
  /**
   * Service to loggin.
   * @param {*} param0 
   * @param {*} user 
   */
  loggin({
    commit,
  }, user) {
    commit('setLoggingLoading', true);
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('email', user.email)
      formData.append('password', user.password)
      axios.post(process.env.VUE_APP_API_URL + '/login', formData).then(response => {
        commit('setUser', response.data);
        localStorage.setItem('token', response.data.access_token);
        commit('setLoggingLoading', false);
        resolve(response);
      }).catch(error => {
        commit('setLoggingLoading', false);
        reject(error.response);
      })
    })
  },

  /**
  * Service pour envoyer un lien de réinitialisation de mot de passe.
  * @param {*} param0 
  * @returns 
  */
  forgotPassword({
    commit,
  }, info) {
    commit('setUserUpdating', true)
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/forgotPassword', info, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUserUpdating', false)
        resolve(response)
      }).catch(error => {
        commit('setUserUpdating', false)
        reject(error)
      })
    })
  },


  /**
   * Service to logout.
   * @returns 
   */
  logout() {
    return new Promise((resolve) => {
      localStorage.removeItem('token')
      resolve()
    })
  },



  /**
   * Service to get the current connected user.
   * @param {*} param0 
   * @param {*} user 
   */
  getUser({
    commit,
  }) {
    commit('setLoggingLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/me', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUser', response.data);
        commit('setLoggingLoading', false);
        resolve(response);
      }).catch(error => {
        commit('setLoggingLoading', false);
        localStorage.removeItem("token");
        reject(error.response);
      })
    })
  },

  /**
   * Service pour récupérer tous les utilisateurs.
   * @param {*} param0 
   * @param {*} user 
   */
  getUsers({
    commit,
  }) {
    commit('setUsersLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/users', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUsers', response.data);
        commit('setUsersLoading', false);
        resolve(response);
      }).catch(error => {
        commit('setUsersLoading', false);
        localStorage.removeItem("token");
        reject(error.response);
      })
    })
  },

  /**
   * Service to get agency users.
   * @param {*} param0 
   * @param {*} user 
   */
  getAgencyUsers({
    commit,
  }, agencyId) {
    commit('setUsersLoading', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.get(process.env.VUE_APP_API_URL + '/agencies/' + agencyId + '/users', {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUsers', response.data);
        commit('setUsersLoading', false);
        resolve(response);
      }).catch(error => {
        commit('setUsersLoading', false);
        localStorage.removeItem("token");
        reject(error.response);
      })
    })
  },

  /**
   * Service to create an user.
   * @param {*} param0 
   * @param {*} order 
   * @returns 
   */
  createAgencyUser({
    commit,
  }, user) {
    commit('setUserUpdating', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.post(process.env.VUE_APP_API_URL + '/agencies/' + user.agencyId + '/users', user, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUserUpdating', false);
        resolve(response);
      }).catch(error => {
        commit('setUserUpdating', false);
        reject(error);
      })
    })
  },

  /**
   * Service pour mettre à jour un utilisateur.
   * @param {*} param0 
   * @param {*} order 
   * @returns 
   */
  updateUser({
    commit,
  }, user) {
    commit('setUserUpdating', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.put(process.env.VUE_APP_API_URL + '/users/' + user.id, user, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUserUpdating', false)
        resolve(response);
      }).catch(error => {
        commit('setUserUpdating', false)
        reject(error);
      })
    })
  },

  /**
   * Service to delete an user.
   * @param {*} param0 
   * @param {*} order 
   * @returns 
   */
  deleteUser({
    commit,
  }, info) {
    commit('setUserUpdating', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.delete(process.env.VUE_APP_API_URL + '/agencies/'+ info.agencyId +'/users/' + info.userId, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUserUpdating', false);
        resolve(response);
      }).catch(error => {
        commit('setUserUpdating', false);
        reject(error);
      })
    })
  },

  /**
   * Service to delete an user.
   * @param {*} param0 
   * @param {*} order 
   * @returns 
   */
  activeUser({
    commit,
  }, info) {
    commit('setUserUpdating', true);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token')
      const AuthStr = 'Bearer ' + token
      axios.patch(process.env.VUE_APP_API_URL + '/agencies/'+ info.agencyId +'/users/' + info.userId + '/active', {}, {
        headers: {
          Accept: 'application/json',
          Authorization: AuthStr,
        }
      }).then(response => {
        commit('setUserUpdating', false);
        resolve(response);
      }).catch(error => {
        commit('setUserUpdating', false);
        reject(error);
      })
    })
  },


  /**
   * Service to reset password.
   * @param {*} param0 
   * @param {*} passwordInfo 
   * @returns 
   */
  resetPassword({
    commit
  }, passwordInfo) {
    commit('setLoggingLoading', true);
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_API_URL + '/passwords', passwordInfo, {
        headers: {
          Accept: 'application/json'
        }
      }).then(response => {
        commit('setLoggingLoading', false)
        localStorage.setItem('token', response.data.access_token)
        resolve(response);
      }).catch(error => {
        commit('setLoggingLoading', false)
        reject(error);
      })
    })
  }

}


const getters = {
  loggingLoading: state => state.loggingLoading,
  user: state => state.user,
  users: state => state.users,
  usersLoading: state => state.usersLoading,
  userUpdating: state => state.userUpdating
}

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setLoggingLoading(state, loggingLoading) {
    state.loggingLoading = loggingLoading;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setUsersLoading(state, usersLoading) {
    state.usersLoading = usersLoading;
  },
  setUserUpdating(state, userUpdating) {
    state.userUpdating = userUpdating
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}