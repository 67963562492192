import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import "@mdi/font/css/materialdesignicons.min.css"

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#625BF9',
            secondary: '8FBC94',
            accent: '#2196f3',
            error: '#f44336',
            warning: '#ff9800',
            info: '#03a9f4',
            success: '#4caf50',
            gold: '#ffd700'
          },
          dark: {
            primary: '#625BF9',
            secondary: '8FBC94',
            accent: '#2196f3',
            error: '#f44336',
            warning: '#ff9800',
            info: '#03a9f4',
            success: '#4caf50',
            gold: '#ffd700'
          },
        },
      },
});
