import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './registerServiceWorker'
import snackbar from './mixins/snackbar'

Vue.config.productionTip = false
Vue.mixin(snackbar)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
