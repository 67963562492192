import {
  mapActions,
  mapGetters
} from 'vuex';
import ForgetPasswordI18n from './ForgetPassword.i18n.js';

export default {

  components: {},

  props: {},

  computed: {
    ...mapGetters(['userUpdating'])
  },

  data() {
    return {
      isValid: false,
      email: '',
      resetSuccess: false,
      unknownMail: false,
      error: false,
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'email doit être valide"
      ],
    };
  },

  watch: {
    email(value) {
      this.validateEmail(value)
    }
  },

  methods: {
    ...mapActions(['forgotPassword']),
    forgotCurrentPassword() {
      const info = {
        email: this.email
      }
      this.forgotPassword(info).then(() => {
        this.resetSuccess = true
        this.unknownMail = false
        this.error = false
        this.$router.push('/resetMailSent')
      }).catch(err => {
        if (err.response.status === 404) {
          this.unknownMail = true
        } else {
          this.error = true
        }
        console.log(err)
      })
    },
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  i18n: ForgetPasswordI18n,

};