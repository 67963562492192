<template>
  <v-theme-provider root>
    <router-view />
  </v-theme-provider>
</template>

<script>
  
export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>